import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CookiesService } from '@providers/cookies/cookies.service';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { localStorageFactory } from '@providers/local-storage/local-storage.factory';
import { Platform } from '@providers/platform/platform';

import { DeviceDetectorService } from '../device-ks/device-detector.service';
import { Movistar } from '@providers/device-ks/movistar-device';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [{ provide: APP_INITIALIZER, useValue: () => LocalStorageModule._loading, multi: true }],
})
export class LocalStorageModule {
  private static _loading: Promise<boolean>;

  constructor(deviceDetector: DeviceDetectorService, localStorageService: LocalStorage, private movistar: Movistar) {
    LocalStorageModule._loading = new Promise(resolve => {
      if (deviceDetector.checkIfMovistar()) {
        this.movistar.isReady.subscribe((isReady) => {
          if(isReady) {
            localStorageService.$ready.next(true);
          }
        });
      } else {
        localStorageService.$ready.next(true);
        resolve(true);
      }
    });
  }
  static detect(): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [
        {
          provide: LocalStorage,
          useFactory: localStorageFactory,
          deps: [Platform, CookiesService, DeviceDetectorService],
        },
      ],
    };
  }
}
