import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '@providers/local-storage/local-storage';

import { ChromecastService } from '../chromecast/chromecast.service';
import { LocationService } from '../helpers/location';
import { MetricsService } from '../metrics/metrics.service';
import { DeviceKS } from './device-ks';

@Injectable()
export class DevicePlatformInitializer {
  constructor(
    private chromecast: ChromecastService,
    private deviceKS: DeviceKS,
    private metrics: MetricsService,
    private location: LocationService,
    private injector: Injector,
    private localStorage: LocalStorage
  ) {}

  load(): Promise<any> {
    const force = this.location.getUrlParameter('force_platform') || null;

    return new Promise(async (resolve, reject) => {
      await this.deviceKS
        .init(force as any)
        .then(async (type) => {
          if (!this.localStorage.ready) {
            await this.localStorage.$isReady.toPromise().then(() => {
              console.log('localStorage ready');
            })
            .catch(e => {
              console.error('Error waiting for localStorage ready', e);
            });
          }

          if (this.chromecast.isChromecast()) {
            this.chromecast.init();
          } else if (this.location.getUrlParameter('platform') === 'zeasn') {
            await this.deviceKS.setDeviceType('zeasn');
          }
          resolve(this.chromecast.isChromecast());
        })
        .catch(e => {
          console.error('Error deviceKS init', e);
          reject(e);
        });
    });
  }

  bootstrap(): void {
    console.log('se ejecuta bootstrap');
    this.metrics.abrioApp(this.deviceKS.currentDUID);
    if (this.chromecast.isChromecast()) {
      const router = this.injector.get(Router);
      router.navigate(['cast', 'tv-wait']);
    }
  }
}

export const devicePlatformInitializerFactory =
  (service: DevicePlatformInitializer): (() => Promise<any>) =>
  () =>
    service.load();

export const devicePlatformBootstrapFactory =
  (service: DevicePlatformInitializer): (() => void) =>
  () =>
    service.bootstrap();
